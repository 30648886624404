/**
 * UserService - all about our user
 * - display name, avatar etc
 * - @see auth for login/logout actions etc
 * - @see organization for orgs related stuff (list of our orgs etc)
 */
import { HttpClient } from '@angular/common/http';
import type { Signal, WritableSignal } from '@angular/core';
import { computed, DestroyRef, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { Observable } from 'rxjs';
import { catchError, combineLatest, firstValueFrom, iif, map, of, switchMap, tap } from 'rxjs';

import { type Avatar, type Maybe, replaceParams } from '@evc/web-components';

import type {
  AcceptInvitationResponse,
  ApiOrganization,
  ApiOrganizationCreatePayload,
  ApiOrganizationFetchResponse,
  ApiOrganizationUsersFetchResponse,
  ApiPaginatedOrganizationResponse,
  ApiPaginatedOrgUsersResponse,
  Organization,
  Role,
  SendInvitationRequest,
  SendInvitationResponse,
  UserOrganization,
} from '../../core-client/organizations/organizations.type';
import { PlatformConfigService } from '../../services/config/config.service';
import type { ApiResponse, HttpError } from '../../types/api.type';
import { AuthService } from '../auth/service/auth.service';
import type { UserProfile } from '../user/user.type';
import { CoreClientUtilService } from '../utils/core-client-utils.service';

const DEFAULT_ORG_COLOR = 'var(--badge-border)';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {
  #destroyRef = inject(DestroyRef);
  #authService = inject(AuthService);
  #httpClient = inject(HttpClient);
  #configService = inject(PlatformConfigService);
  #CoreClientUtilService = inject(CoreClientUtilService);

  #currentOrgId:WritableSignal<string|undefined> = signal(undefined);
  get currentOrgId():Signal<string|undefined> {
    return this.#currentOrgId.asReadonly();
  }

  #entries:WritableSignal<UserOrganization[]> = signal([]);
  entries = computed<UserOrganization[]>(() => {
    const current = this.#currentOrgId();

    return this.#entries().map((entry) => ({
      ...entry,
      current: entry.id === current,
    }));
  });

  // ready after fetching orgs
  #ready = signal(false);
  get ready():Signal<boolean> {
    return this.#ready.asReadonly();
  }

  get current():Signal<UserOrganization|undefined> {
    return computed(() => {
      if (!this.#currentOrgId()) return undefined;

      return this.entries().filter((entry) => entry.id === this.#currentOrgId())?.[0];
    });
  }

  isCurrentUserAdminOrOwner = computed(() => {
    const currentOrg = this.current();

    return currentOrg?.roles?.some((role) => ['Admin', 'Owner'].includes(role.name)) ?? false;
  });

  currentOrganizationInfos: WritableSignal<Maybe<Organization>> = signal(undefined);

  /**
   * simply set current org ID > trigger computed so list always with {current:boolean}
   * @param followUpLogin - true only if need to retrigger auth login (new token) (eg on org click - if greenfield)
   */
  setCurrent(id:string | undefined, followUpLogin=false):Observable<void> {
    this.#currentOrgId.set(id);
    if (this.#configService.greenfield && id && followUpLogin) {
      return this.#authService.login({
        extraQueryParameters: { toid: id },
      });
    }

    return of(undefined);
  }

  setEntries(organizations:UserOrganization[] | ApiOrganizationCreatePayload[] | ApiOrganizationFetchResponse[]):UserOrganization[] {
    const computedOrgs: UserOrganization[] = organizations.map((organization) => this.#computeUserOrganization(organization));
    this.#entries.set(computedOrgs);
    const currentId = computedOrgs.find((org) => org.current)?.id;
    if (currentId) {
      this.setCurrent(currentId);
    } else {
      const newMatchId = this.#currentOrgId() && computedOrgs.find((org) => org.id === this.#currentOrgId())?.id;
      const newCurrentId = newMatchId || computedOrgs[0]?.id;
      this.setCurrent(newCurrentId);
    }

    return computedOrgs;
  }

  addEntry(organization:UserOrganization | ApiOrganizationCreatePayload): UserOrganization {
    const computedOrg: UserOrganization = this.#computeUserOrganization(organization);
    this.#entries.update((organizations) => [...organizations, computedOrg]);
    this.setCurrent(computedOrg.id);

    return computedOrg;
  }

  /** fetch organization list and set current one
   * * expected to call this when aquire token (cf platform/app.component) */
  async init(toid?:string|undefined):Promise<void> {
    this.setCurrent(toid);
    await this.updateOrganizations();
    this.#ready.set(true);
  }

  async updateOrganizations():Promise<UserOrganization[]> {
    return firstValueFrom(this.#fetchOrganizations$())
    .then(organizations => this.setEntries(organizations));
  }

  /** check if an organization already exists with this name.
   * resolve only if success
   */
  async checkAlreadyExist(name:string):Promise<boolean> {
    return firstValueFrom(this.#checkAlreadyExist$(name));
  }

  /** create a new org (call api)
   * then add it to our entries
   * ! may only be used by organization app where it will redirect on creation success
   */
  async createOrganization(payload:ApiOrganizationCreatePayload): Promise<void> {
    return firstValueFrom(this.#createOrganization$(payload));
  }

  /** return the list of users for the current organization
   * resolve only if success
   */
  async getUserList(): Promise<UserProfile[]> {
    return firstValueFrom(this.#fetchUserList$())
      .then((users) => this.#computeOrgUsers(users));
  }

  async fetchCurrentOrgInfos(): Promise<Organization> {
    const currentOrganization = this.currentOrganizationInfos();

    return firstValueFrom(
      iif(
        () => !!currentOrganization,
        of(currentOrganization!),
        combineLatest([this.#fetchCurrentOrg$(), this.#fetchCurrentOrgRoles$()]).pipe(
          takeUntilDestroyed(this.#destroyRef),
          map(([organization, roles]) => this.#computeOrganization(organization, roles)),
          tap((organization) => this.currentOrganizationInfos.set(organization)),
        ),
      ),
    );
  }

  /** send invitation to a user
   * expected to be called from the admin app, by an admin
   * - use token to identify our user as an admin (the sender)
   * - verify that the user is not already in the organization
   * - also provide invitation token so api can fetch which organization etc
   *
   * !!! FAKE CALL - to be implemented !!!
   */
  sendUserInvitation$({ email, role } : SendInvitationRequest): Observable<SendInvitationResponse> {
    return this.#sendUserInvitation$(email, role);
  }

  /** register a guest user into some organization
   * expected to be called after invitation flow
   * - use token to identify our guest user
   * - also provide invitation token so api can fetch which organization etc
   *
   * !!! FAKE CALL - to be implemented !!!
   */
  confirmUserInvitation$(invitationToken:string): Observable<AcceptInvitationResponse> {
    return this.#confirmUserInvitation$(invitationToken);
  }

  /** change user role in an organization
   * expected to be called from the admin app, by an admin or owner
   */
  changeUserRole$(userId: string, roleId: string):Observable<boolean> {
    return this.#changeUserRole$(userId, roleId);
  }

  /** remove a user from an organization
   * expected to be called from the admin app, by an admin or owner
   * - use userId to identify the user to delete
   *
   * !!! FAKE CALL - to be implemented !!!
   */
  removeUserFromOrganization$(userId: string): Observable<boolean> {
    return this.#removeUserFromOrganization$(userId);
  }

  // !!! FAKE CALL - to be implemented !!!
  #removeUserFromOrganization$(userId: Maybe<string>): Observable<boolean> {
    console.warn('[todo] implement removeUserFromOrganization$', userId);

    return of(true);
  }

  #changeUserRole$(userId: string, roleId: string): Observable<boolean> {
    const { uri, endpoints } = this.#configService.get('api')!;
    const apiUri = `${uri}${endpoints.organization.changeRole}`;

    return this.#authService.requestAccessToken()
    .pipe(
      takeUntilDestroyed(this.#destroyRef),
      switchMap(() => this.#httpClient.patch<ApiResponse<boolean>>(replaceParams(apiUri, { userId, roleId }), null)),
      map(({ result }) => result),
    );
  }
  /** post api returns different format that the get one
   * here we ensure we have the same format
   */
  #computeUserOrganization(data: Partial<UserOrganization | ApiOrganizationCreatePayload | ApiOrganizationFetchResponse>): UserOrganization {
    const id = (data as UserOrganization).id || (data as ApiOrganizationFetchResponse).organizationId;
    const name = (data as UserOrganization | ApiOrganizationCreatePayload).name || (data as ApiOrganizationFetchResponse).organizationName || '';
    const logo = (data as ApiOrganizationFetchResponse).logo;
    let roles = (data as UserOrganization | ApiOrganizationFetchResponse).roles;

    if (!name) throw new Error('UserOrganization name is required');

    if (!id) throw new Error('UserOrganization id is required');

    if (!roles) roles = [];

    return {
      id,
      name,
      current: (data as UserOrganization).current ?? false,
      avatar: (data as UserOrganization).avatar ?? this.#generateOrgAvatar(name, logo),
      roles: this.#CoreClientUtilService.computeRoles(roles),
    };
  }

  #generateOrgAvatar(name: string, logo?: string): Avatar {
    if (logo) {
      return {
        type: 'image',
        src: logo,
      };
    } else {
      return {
        type: 'initials',
        color: DEFAULT_ORG_COLOR,
        light: true,
        initials: this.#computeOrgInitials(name),
      };
    }
  }

  #computeOrgInitials(name: string): string {
    return name.split(' ')
      .map((word) => word[0].toUpperCase())
      .join('')
      .slice(0, 2);
  }

  #computeOrgUsers(usersResponse: ApiOrganizationUsersFetchResponse[]): UserProfile[] {
   return usersResponse.map((userResponse: ApiOrganizationUsersFetchResponse) =>
      this.#CoreClientUtilService.computeUserProfile({
        ...userResponse.user,
        roles: userResponse.roles!,
      }),
    );
  }

  // * ---- API CALLS IMPLEMENTATIONS ---- * //

  #fetchUserList$(): Observable<ApiOrganizationUsersFetchResponse[]> {
    const { uri, endpoints } = this.#configService.get('api')!;
    const apiUri = `${uri}${endpoints.organization.listUsers}`;

    return this.#authService.requestAccessToken()
    .pipe(
      takeUntilDestroyed(this.#destroyRef),
      switchMap(() => this.#httpClient.get<ApiPaginatedOrgUsersResponse>(apiUri)),
      map(({ result }) => result),
      // do not care about pagination here
      map(({ items }) => items),
      catchError(() => of([] as ApiOrganizationUsersFetchResponse[])),
    );
  }

  #fetchOrganizations$(): Observable<ApiOrganizationFetchResponse[]> {
    const { uri, endpoints } = this.#configService.get('api')!;
    const apiUri = `${uri}${endpoints.organization.list}`;

    return this.#authService.requestAccessToken()
    .pipe(
      takeUntilDestroyed(this.#destroyRef),
      switchMap(() => this.#httpClient.get<ApiPaginatedOrganizationResponse>(apiUri, { params: { GetAll: true } })),
      map(({ result }) => result),
      // do not care about pagi;
      map(({ items }) => items),
      catchError(() => of([] as ApiOrganizationFetchResponse[])),
    );
  }

  #checkAlreadyExist$(name:string): Observable<boolean> {
    const { uri, endpoints } = this.#configService.get('api')!;
    const apiUri = (`${uri}${endpoints.organization.exists}`);

    return this.#authService.requestAccessToken()
    .pipe(
      takeUntilDestroyed(this.#destroyRef),
      switchMap(() => this.#httpClient.get<boolean>(apiUri, { params: { Name: name } })),
      // result true if not exist - else fail 422...
      catchError((errorHttp:HttpError) => {
        const firstError = errorHttp.error?.errors?.[0];

        throw (firstError ?? errorHttp) as Error;
      }),
    );
  }

  #createOrganization$(payload:ApiOrganizationCreatePayload):Observable<void> {
    const { uri, endpoints } = this.#configService.get('api')!;
    const apiUri = `${uri}${endpoints.organization.create}`;

    return this.#authService.requestAccessToken()
    .pipe(
      takeUntilDestroyed(this.#destroyRef),
      switchMap(() => this.#httpClient.post<void>(apiUri, payload)),
    );
  }

  #fetchCurrentOrg$(): Observable<ApiOrganization> {
    const { uri, endpoints } = this.#configService.get('api')!;
    const apiUri = `${uri}${endpoints.organization.current}`;

    return this.#authService.requestAccessToken()
    .pipe(
      takeUntilDestroyed(this.#destroyRef),
      switchMap(() => this.#httpClient.get<ApiResponse<ApiOrganization>>(apiUri)),
      map(({ result }) => result),
      catchError((errorHttp:HttpError) => {
        throw errorHttp.error.errors as unknown as Error;
      }),
    );
  }

  #fetchCurrentOrgRoles$(): Observable<Role[]> {
    const { uri, endpoints } = this.#configService.get('api')!;
    const apiUri = `${uri}${endpoints.organization.roles}`;

    return this.#authService.requestAccessToken()
    .pipe(
      takeUntilDestroyed(this.#destroyRef),
      switchMap(() => this.#httpClient.get<ApiResponse<Role[]>>(apiUri)),
      map(({ result }) => result),
      catchError((errorHttp:HttpError) => {
        throw errorHttp.error.errors as unknown as Error;
      }),
    );
  }

  #computeOrganization(organization: ApiOrganization, roles: Role[] = []): Organization {
    return {
      id: organization.id,
      name: organization.name,
      phone: organization.phone,
      addresses: organization.addresses,
      eTag: organization.eTag,
      roles: this.#CoreClientUtilService.computeRoles(roles),
    };
  }

  #sendUserInvitation$(email:string, roleId:string): Observable<SendInvitationResponse> {
    const { uri, endpoints } = this.#configService.get('api')!;
    const apiUri = `${uri}${endpoints.organization.sendInvitation}`;

    return this.#authService.requestAccessToken()
    .pipe(
      takeUntilDestroyed(this.#destroyRef),
      switchMap(() => this.#httpClient.post<SendInvitationResponse>(apiUri, { email, roleId })),
      catchError((errorHttp:HttpError) => {
        throw errorHttp.error.errors as unknown as Error;
      }),
    );
  }

  #confirmUserInvitation$(invitationToken:string): Observable<AcceptInvitationResponse> {
    const { uri, endpoints } = this.#configService.get('api')!;
    const apiUri = `${uri}${endpoints.organization.acceptInvitation}`;

    return this.#authService.requestAccessToken()
    .pipe(
      takeUntilDestroyed(this.#destroyRef),
      switchMap(() => this.#httpClient.post<AcceptInvitationResponse>(apiUri, { invitationToken })),
      catchError((errorHttp:HttpError) => {
        throw errorHttp.error.errors?.[0] as unknown as Error;
      }),
    );
  }
}
