export enum Authorities {
  SIGN_UP_SIGN_IN = 'signUpSignIn',
  RESET_PASSWORD = 'resetPassword',
  // EDIT_PROFILE = 'editProfile',
}

// https://learn.microsoft.com/en-us/azure/active-directory-b2c/error-codes
export enum ADB2CErrors {
  CANCELED_OPERATION = 'AADB2C90091',
  // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/7115
  AQUIRE_TOKEN_SILENT_EXPIRED_SESSION = 'AADB2C90077',
}

export type AuthAuthorities = `${Authorities}`;
export type AuthPolicies = Record<AuthAuthorities, string>
export type AuthB2CPolicies = {
  names: Record<AuthAuthorities, string>;
  authorities: Record<AuthAuthorities, string>;
  authorityDomain: string;
}

export type AuthRedirects = {
  // flow redirect here after complete (login) - eg back to your app
  success: string,
  // flow redirect here if any fail - Expect PUBLIC uri eg public website
  fail: string,
  // step create organization !== aadb2c - so use this uri to redirect
  organization: string,
}

export type AuthConfig = {
  clientId: string
  authorityDomain: string,
  authorityTenantId: string,
  policies: AuthPolicies
  redirects: AuthRedirects
  scopes: string[]
  b2cPolicies: AuthB2CPolicies
  // app may need user to be part of at least 1 organization (would redirect to create one)
  forceOrganization:false|boolean
  // may allow to keep last session on reload (off by default)
  enableSessionOnReload:false|boolean
}
