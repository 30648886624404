import { inject, Injectable } from '@angular/core';

import type { Avatar, Maybe } from '@evc/web-components';

import { PlatformConfigService } from '../../services/config/config.service';
import type { Role, UserOrganization } from '../organizations/organizations.type';
import type { UserApi, UserApiPossibilities, UserIMSApi, UserProfile, UserRoleIMS } from '../user/user.type';

const DEFAULT_USER_COLOR = '#93C6F9';
const DEFAULT_USER_CHAR = '.';
const OWNER_ID = '00000000-0000-0000-0000-000000000001';

@Injectable({
  providedIn: 'root',
})
export class CoreClientUtilService {
  #configService = inject(PlatformConfigService);

  computeUserProfile(data: UserApi): UserProfile {
    const user = data as UserApiPossibilities;
    const id = user.id ?? user.tenantId;
    const fromDisplayName = (user.displayName && user.displayName !== 'unknown') && user.displayName;
    const fromFormalName = (user.givenName || user.surname) && [user.givenName, user.surname].join(' ');
    const displayName = fromDisplayName || fromFormalName
      || user.email?.split('@')[0]?.split(/[.,+-]/).join(' ') || id;
    const { email } = user;
    const roles = user.roles ?? (user as UserIMSApi).role;

    return {
      id,
      displayName,
      givenName: user.givenName ?? '',
      surname: user.surname ?? '',
      email,
      emailVerified: user.emailVerified ?? false,
      phone: user.phone ?? '',
      preferredLanguage: user.preferredLanguage,
      address: user.address,
      roles: this.computeRoles(roles),
      avatar: this.computeAvatar({ ...user, formalName: fromFormalName || displayName }),
      // TODO : API must return lastUpdatedPassword
      lastUpdatedPassword: user.lastUpdatedPassword,
    };
  }

  computeAvatar(user: UserApiPossibilities): Avatar {
    const { formalName, userImage, color } = user;
    if (userImage) {
      return {
        type: 'image',
        src: userImage,
      };
    }

    const initials = this.#computeUserInitials(formalName!);

    const colorDefaulted = color ?? DEFAULT_USER_COLOR;
    // TODO compute light/dark text
    const isLight = !!colorDefaulted;

    return {
      type: 'initials',
      color: colorDefaulted,
      light: isLight,
      initials,
    };
  }

  computeRoles(roles: Maybe<UserRoleIMS | Role[]>): Role[] {
    if (!roles) return [];

    if (typeof(roles) === 'string') {
      return [
        {
          id: '0',
          name: roles,
          isReadOnly: false,
        },
      ];
    }

    if (roles.length < 1) return [];

    return roles;
  }

  getRoleList(organization: UserOrganization): string {
    if (!organization.roles) {
      return '';
    }

    const roles: Role[] = organization.roles;

    let roleList = '';
    roles?.forEach((role, index) => {
      roleList += role.name;
      if (index < roles.length - 1) {
        roleList += ', ';
      }
    });

    return roleList;
  }

  isOwner(id: Maybe<string>): boolean {
    return id === OWNER_ID;
  }

  #computeUserInitials(formalName: string): string {
    const words = formalName.split(' ') ?? [];

    const firstWord = (words.length > 0 && words[0]) || DEFAULT_USER_CHAR;
    const lastWord = words.length > 1 && words[words.length - 1];

    const firstWordLetterOne = (firstWord.length > 0 && firstWord[0].toLocaleUpperCase()) || DEFAULT_USER_CHAR;
    const firstWordLetterTwo = (firstWord.length > 1 && firstWord[1].toLocaleLowerCase()) || DEFAULT_USER_CHAR;
    const lastWordInitial = (lastWord && lastWord[0].toLocaleUpperCase()) || firstWordLetterTwo;

    return [
      firstWordLetterOne,
      lastWordInitial,
    ].join('');
  }
}
