import type { IconListType } from './icon-list.type';

export enum ToastTypes {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
}
export type ToastType = `${ToastTypes}`;

export type ToastAction = {
  text: string;
  callback: () => void;
};

export type Toast = {
  id: string
  type: ToastType
  message: string
  action?: ToastAction
  duration?: number
  icon?: IconListType
};

export enum ToastDurations {
  INFO = 6000,
  SUCCESS = 3000,
  ERROR = 8000
}
