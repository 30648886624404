import { computed, Injectable, signal } from '@angular/core';

import { IconEnum } from '../../types/icon-list.type';
import type { Toast } from '../../types/toast.type';
import { ToastDurations, ToastTypes } from '../../types/toast.type';
import type { Maybe } from '../../utils/types';

@Injectable({ providedIn: 'root' })
export class ToastService {
  #uniqId = 0;
  #queue = signal<Toast[]>([]);
  current = computed(() => this.#queue().at(-1));

  getById(idToFind:string): Maybe<Toast> {
    return this.#queue().find(({ id }) => id === idToFind);
  }

  add(payload: Partial<Toast>&{message:string}): Toast {
    const toast = {
      id: payload.id || this.#getUniqId('toast'),
      type: ToastTypes.INFO,
      duration: payload.duration ?? ToastDurations.INFO,
      ...payload,
    };

    this.#queue.update(queue => [...queue, toast]);

    if (toast.duration > 0) {
      setTimeout(() => this.remove(toast.id), toast.duration);
    }

    return toast;
  }

  success(message:string, options?: Partial<Toast>): Toast {
    return this.add({
      icon: IconEnum.CircleCheck,
      type: ToastTypes.SUCCESS,
      duration: ToastDurations.SUCCESS,
      message, ...options,
    });
  }

  info(message:string, options?: Partial<Toast>): Toast {
    return this.add({
      icon: IconEnum.CircleCheck,
      type: ToastTypes.INFO,
      duration: ToastDurations.INFO,
      message, ...options,
    });
  }

  error(message:string, options?: Partial<Toast>): Toast {
    return this.add({
      icon: IconEnum.CircleCheck,
      type: ToastTypes.ERROR,
      duration: ToastDurations.ERROR,
      message, ...options,
    });
  }

  remove(id: string): void {
    this.#queue.update(queue => queue.filter((toast) => id !== toast.id));
  }

  #getUniqId(prefix = 'toast'): string {
    return `${prefix}-${++this.#uniqId}`;
  }
}
