import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IconListType } from '../../types/icon-list.type';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';

@Component({
  selector: 'evc-button',
  standalone: true,
  imports: [SvgIconComponent, NgClass],
  templateUrl: './nav-button.component.html',
  styleUrls: ['./nav-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavButtonComponent {
  @Output() readonly ctaClicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  @Input() disabled = false;
  @Input() selected = false;
  @Input() type:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'cta'
    | 'link'
    | 'error-primary'
    | 'icon'
    | 'error-secondary'
    | 'error-tertiary' = 'primary';

  @Input() size: 'lg' | 'sm' | 'xs' | 'xxl' = 'lg';
  @Input() variant: 'round' | 'square' | 'semi' = 'semi';
  @Input() leftIcon?: IconListType;
  @Input() rightIcon?: IconListType;
  @Input() noPadding = false;

  onMouseMove(event: MouseEvent): void {
    const target: HTMLElement = event.target as HTMLElement;
    if (target.classList.contains('evc-btn--cta')) {
      const rect: DOMRect = target.getBoundingClientRect();
      const x: number = event.clientX - rect.left;
      const y: number = event.clientY - rect.top;
      target.style.setProperty('--x', `${x}px`);
      target.style.setProperty('--y', `${y}px`);
    }
  }

  handleCtaClick(event: MouseEvent): void {
    this.ctaClicked.emit(event);
  }

  getIconSize(): 'xs' | 'sm' | 'md' {
    if (['lg', 'xxl'].includes(this.size)) {
      return 'md';
    }

    return this.size as 'xs' | 'sm';
  }
}
