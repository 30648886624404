import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

import { I18nPlatformService } from '../services/i18n/i18n.service';

@Pipe({
  name: 'platformTranslate',
  standalone: true,
  pure: true,
})
export class PlatformTranslatePipe implements PipeTransform {
  #i18nPlatformService:I18nPlatformService;

  constructor(i18nPlatformService:I18nPlatformService) {
    this.#i18nPlatformService = i18nPlatformService;
  }

  transform(value: string): string {
    return this.#i18nPlatformService.tSignal(value)();
  }
}
