/**
 * UserService - all about our user
 * - display name, avatar etc
 * - @see auth for login/logout actions etc
 * - @see organization for orgs related stuff (list of our orgs etc)
 */
import { HttpClient } from '@angular/common/http';
import type { Signal, WritableSignal } from '@angular/core';
import { DestroyRef, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DomSanitizer } from '@angular/platform-browser';
import type { Observable } from 'rxjs';
import { firstValueFrom, map, switchMap } from 'rxjs';

import type { Maybe } from '@evc/web-components';

import type { ApiProductsResponse, ApiSaveUserRequest, ComputedProduct, UserApi, UserGreenfieldApi, UserProfile } from '../../core-client/user/user.type';
import { PlatformConfigService } from '../../services/config/config.service';
import type { ApiPaginatedResult, ApiResponse } from '../../types/api.type';
import { AuthService } from '../auth/service/auth.service';
import { CoreClientUtilService } from '../utils/core-client-utils.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  #destroyRef = inject(DestroyRef);
  #CoreClientUtilService = inject(CoreClientUtilService);
  #profile:WritableSignal<Maybe<UserProfile>> = signal(undefined);
  #ready = signal(false);
  #authService = inject(AuthService);
  #httpClient = inject(HttpClient);
  private _configService = inject(PlatformConfigService);
  private sanitizer = inject(DomSanitizer);

  #computedProducts: WritableSignal<Maybe<ComputedProduct[]>> = signal(undefined);

  // ready after fetching orgs
  get ready(): Signal<boolean> {
    return this.#ready.asReadonly();
  }
  get profile(): Signal<Maybe<UserProfile>> {
    return this.#profile.asReadonly();
  }
  get isAvatarImage(): boolean {
    return this.profile()?.avatar?.type === 'image';
  }

  setUser(user: UserApi|undefined):Maybe<UserProfile> {
    const profile = user
      ? this.#CoreClientUtilService.computeUserProfile(user)
      : undefined;
    this.#profile.set(profile);

    return profile;
  }

  updateUser(data: Partial<UserApi>):UserProfile {
    this.#profile.update((currentProfile) => ({
      ...(currentProfile ?? {}),
      ...data,
    } as UserProfile));

    return this.#profile()!;
  }

  /** fetch user profile
   * * expected to call this when aquire token (cf platform/app.component) */
  async init(): Promise<Maybe<UserProfile>> {
    await this.updateProfile();
    this.#ready.set(true);

    return this.profile();
  }

  /** fetch user info
   * - remap data to fit our app model
   * - then update our profile
   */
  async updateProfile(): Promise<UserProfile> {
    return firstValueFrom(this.#fetchUser$())
    .then(userProfile => this.setUser(userProfile)!)
    .catch(error => {
      this.setUser(undefined);

      throw error;
    });
  }

  saveUser(payload:ApiSaveUserRequest): Promise<UserProfile> {
    return firstValueFrom(this.#saveUser$(payload))
    .then(() => this.updateUser(payload));
  }

  openProfile():void {
    const uri = this._configService.get('uris').user;
    if (!uri) return;
    window.open(uri, '_blank');
  }

  async getUserApplications(): Promise<ComputedProduct[]> {
    if (this.#computedProducts()) {
      return this.#computedProducts()!;
    }

    const applications = await firstValueFrom(this.#fetchUserApplications$());
    const computedProducts = await Promise.all(applications.map(app => this.#getAppsImgFromURL(app)));

    this.#computedProducts.set(computedProducts);

    return computedProducts;
  }

  async #getAppsImgFromURL(app: ApiProductsResponse): Promise<ComputedProduct> {
    return fetch(app.icon.url)
      .then(response => response.text())
      .then(svg => this.sanitizer.bypassSecurityTrustHtml(svg))
      .catch(error => {
        console.error('Error fetching SVG:', error);

        return '';
      })
      .then(iconHtml => ({ ...app, iconHtml }));
  }

  // *################### API CALLS OBSERVABLES ###################*

  #fetchUser$() :Observable<UserGreenfieldApi> {
    const { uri, endpoints } = this._configService.get('api')!;
    const apiUri = `${uri}${endpoints.user.me}`;

    return this.#authService.requestAccessToken()
    .pipe(
      takeUntilDestroyed(this.#destroyRef),
      switchMap(() => this.#httpClient.get<ApiResponse<UserGreenfieldApi>>(apiUri)),
      map(({ result }) => result),
    );
  }

  #fetchUserApplications$(): Observable<ApiProductsResponse[]> {
    const { uri, endpoints } = this._configService.get('api')!;
    const apiUri = `${uri}${endpoints.user.applications}`;

    return this.#authService.requestAccessToken()
    .pipe(
      takeUntilDestroyed(this.#destroyRef),
      switchMap(() => this.#httpClient.get<ApiResponse<ApiPaginatedResult<ApiProductsResponse>>>(apiUri)),
      map(({ result }) => result.items),
    );
  }

  #saveUser$(payload:ApiSaveUserRequest): Observable<UserGreenfieldApi> {
    const { uri, endpoints } = this._configService.get('api')!;
    const apiUri = `${uri}${endpoints.user.me}`;

    return this.#authService.requestAccessToken()
    .pipe(
      takeUntilDestroyed(this.#destroyRef),
      switchMap(() => this.#httpClient.put<ApiResponse<UserGreenfieldApi>>(apiUri, payload)),
      map(({ result }) => result),
    );
  }
}
