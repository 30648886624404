import { CommonModule } from '@angular/common';
import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import type { Observable } from 'rxjs';

import { Maybe, NavButtonComponent, SvgIconComponent } from '@evc/web-components';

import { PlatformTranslatePipe } from '../../pipes/platform-translate.pipe';
import { SearchService } from '../../services/search/search.service';

@Component({
  selector: 'evc-search',
  standalone: true,
  imports: [
    CommonModule,
    SvgIconComponent,
    NavButtonComponent,
    FormsModule,
    PlatformTranslatePipe,
  ],
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit {
  @Input() public placeholder:Maybe<string> = undefined;

  #searchService = inject(SearchService);
  searchText$?: Observable<string>;

  public ngOnInit(): void {
    this.searchText$ = this.#searchService.searchText$;
  }

  public searchMe(event: KeyboardEvent): void {
    this.#searchService.searchMe(event);
  }

  public clearSearch(): void {
    this.#searchService.clearSearch();
  }

  public onSearchTextChanged(value: string): void {
    this.#searchService.setSearchText(value);
  }
}
