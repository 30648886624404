/**
 * Auth Service
 * bridge between the MSAL service and the application
 */
import type { Signal } from '@angular/core';
import { computed, DestroyRef, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import type { MsalGuardConfiguration } from '@azure/msal-angular';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalService } from '@azure/msal-angular';
import type { AccountInfo, AuthenticationResult, EndSessionRequest, EventMessage, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import { EventType, InteractionRequiredAuthError, InteractionStatus, InteractionType } from '@azure/msal-browser';
import type { Observable } from 'rxjs';
import { catchError, filter, firstValueFrom, map, merge, of, switchMap, tap, throwError } from 'rxjs';

import type { Maybe } from '@evc/web-components';
import { ToastService } from '@evc/web-components';

import { PlatformConfigService } from '../../../services/config/config.service';
import { I18nPlatformService } from '../../../services/i18n/i18n.service';
import type { AuthConfig } from '../auth.type';
import { ADB2CErrors, Authorities } from '../auth.type';
import { STORAGE_KEYS } from '../providers/auth.providers';

const SSO_PROVIDER_NONE = 'None';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  // TODO : investigate why our flows may not return tfp param in our idTokenClaim
  tfp?: string, // Trust framework policy
  acr?: string, // deprecated : Authentication context class reference
};
type IdTokenClaims = {organizationId:string} & Record<string, unknown>;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  getMsalService():MsalService | null {
    return this.#msalService;
  }
  #msalService = inject(MsalService, { optional: true });
  #msalBroadcastService = inject(MsalBroadcastService, { optional: true });
  #msalGuardConfig = inject<MsalGuardConfiguration>(MSAL_GUARD_CONFIG, { optional: true });
  #platformConfigService = inject(PlatformConfigService);
  #toastService = inject(ToastService);
  #i18nService = inject(I18nPlatformService);
  #destroyRef = inject(DestroyRef);

  /** after auth init flow : true if connected | false if not */
  #isAuthenticated = signal<Maybe<boolean>>(undefined);
  get isAuthenticated():Signal<Maybe<boolean>> {
    return this.#isAuthenticated.asReadonly();
  }
  /** emits when isAuthenticated is defined (true/false) */
  onComplete$ = toObservable(this.#isAuthenticated)
    .pipe(
      filter((isAuthenticated:Maybe<boolean>) => (isAuthenticated !== undefined)),
      map((isAuthenticated) => isAuthenticated as boolean),
      takeUntilDestroyed(this.#destroyRef),
    );

  #accountFound = signal(false);
  get accountFound():Signal<boolean> {
    return this.#accountFound.asReadonly();
  }

  #accessToken = signal<string|undefined>(undefined);
  get accessToken():Signal<string|undefined> {
    return this.#accessToken.asReadonly();
  }
  accessToken$ = toObservable(this.#accessToken).pipe(
    filter((token?:string) => !!token),
    takeUntilDestroyed(this.#destroyRef),
  );

  #config!:AuthConfig;
  get config():AuthConfig {
    return this.#config;
  }
  get clientId(): string {
    return this.config?.clientId;
  }

  #loginHint = signal<Maybe<string>>(undefined);
  get loginHint(): Signal<Maybe<string>> {
    return this.#loginHint.asReadonly();
  }
  set loginHint(loginHint:Maybe<string>) {
    this.#loginHint.set(loginHint);
  }

  // You may store state before a login request and retrieve it after the redirect
  #state = signal<string|undefined>(undefined);
  get state():Signal<string|undefined> {
    return this.#state.asReadonly();
  }

  #idTokenClaims = signal<IdTokenClaims|undefined>(undefined);
  get idTokenClaims():Signal<IdTokenClaims|undefined> {
    return this.#idTokenClaims.asReadonly();
  }
  useSSOProvider = computed<boolean>(() =>
    this.#idTokenClaims()?.selectedSsoProvider !== SSO_PROVIDER_NONE);
  organizationId = computed<Maybe<string>>(() => this.#idTokenClaims()?.organizationId);

  #language = signal<string|undefined>(undefined);
  set language(lang:string) {
    this.#language.set(lang);
  }
  #waitLang$:Observable<string> = toObservable(this.#language).pipe(
    takeUntilDestroyed(this.#destroyRef),
    filter((lang) => lang !== undefined && lang !== ''),
    map((lang?:string) => lang!),
  );

  constructor() {
    if (!this.#platformConfigService.greenfield) return;
    if (!this.#msalService
      || !this.#msalBroadcastService
      || !this.#msalGuardConfig) {
      throw new Error('AuthService : MSAL not provided');
    }

    this.#config = this.#platformConfigService.get('auth')!;
  }

  /** Start our init flow
   * - skiped if not greenfield
   * @returns Promise<boolean> - if connected with valid token
   *
   * * do not forget to call this on app init !
   **/
  async init(): Promise<boolean> {
    if (!this.#config) return false;

    // important because standalone
    this.#msalService!.instance.enableAccountStorageEvents();

    const flow = this.#msalService!.handleRedirectObservable()
    .pipe(
      catchError((error) => {
        this.#debug('handleRedirectObservable error', { error });

        throw error;
      }),
      switchMap(() => this.#checkConnection$()),
      switchMap(() => this.#checkValidToken$()),
      switchMap(() => of(true)),
      catchError(() => of(false)),
      takeUntilDestroyed(this.#destroyRef),
    );

    this.#handleMsalEvents();

    return firstValueFrom(flow);
  }

  #handleMsalEvents() {
    merge(
      // after redirected from AADB2C
      this.#redirectSuccess$().pipe(tap(this.#onRedirectSuccess.bind(this))),
      // fail requireToken => re-login
      this.#aquireTokenFailure$().pipe(
        tap((response) => this.#debug('aquireTokenFailure', response)),
        // Todo only if current route protected
        tap(() => this.login()),
      ),
      // fail login
      this.#loginFailure$().pipe(tap(this.#onLoginFailure.bind(this))),
      // after all - are we connected etc
      this.#inProgress$().pipe(tap(this.#onInProgress.bind(this))),
    ).pipe(
      takeUntilDestroyed(this.#destroyRef),
    ).subscribe();
  }

  login(userFlowRequest?: Partial<RedirectRequest>/* | PopupRequest*/, extra?: Partial<RedirectRequest>):Observable<void> {
    const { redirects } = this.#config;
    const payload:RedirectRequest & {extraQueryParameters:Record<string, string>} = {
      scopes: [],
      extraQueryParameters: {},
      redirectUri: redirects.success,
      ...(this.#msalGuardConfig!.authRequest ?? {}),
      ...(userFlowRequest??{}),
      ...extra ?? {},
    };

    const lang = this.#language();
    if (lang) {
      payload.extraQueryParameters = {
        ui_locales: lang,
        ...payload.extraQueryParameters,
      };
    }

    this.#debug('login', { payload });

    return this.#msalService!.loginRedirect(payload);
  }

  /**
   * We force to pass an account so ADB can use Logout Front Channel to logout from any other apps (of same tenant)
   * @see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/logout.md#logoutredirect
   */
  logout(logoutRequest?: EndSessionRequest):Observable<void> {
    const activeAccount = this.#msalService!.instance.getActiveAccount();
    const homeAccount = activeAccount?.homeAccountId && this.#msalService!.instance.getAccountByHomeId(activeAccount.homeAccountId);
    const account = homeAccount || activeAccount;

    logoutRequest = { account, ...logoutRequest };

    this.#debug('logout', { logoutRequest });

    return this.#msalService!.logoutRedirect(logoutRequest);
  }

  resetPassword():Observable<void> {
    const authority = this.#config.b2cPolicies.authorities[Authorities.RESET_PASSWORD];

    return this.login({
      authority,
      loginHint: this.loginHint(),
    });
  }

  /** request accesstoken routine
   * - first try to fetch localy + test if still valid for enough time
   * - if no try to use refreshToken
   * - if no use a login
   */
  requestAccessToken(scopes: string[] = [], extra?:Partial<SilentRequest>):Observable<
    void | AuthenticationResult | (AuthenticationResult & { account: AccountInfo; })
  > {
    const { b2cPolicies, redirects } = this.#config;
    const account = this.#msalService!.instance.getActiveAccount();
    if (!account) {
      this.logout();

      throw new Error('requestAccessToken : No active account found !');
    }
    // need at least 1 scope or no cache : https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/3526
    const scopesWithDefault = Array.from(new Set([...scopes, this.#config.clientId]));

    let request: SilentRequest = {
      authority: b2cPolicies.authorities[Authorities.SIGN_UP_SIGN_IN],
      scopes: scopesWithDefault,
      account,
      redirectUri: redirects.success,
      tokenBodyParameters: {},
      state: this.state(),
    };

    if (extra) {
      request = { ...request, ...extra };
    }

    // If 1st navigate : skip toid to fetch last connected one
    // but if reload - we want to stay to the old one
    const toid = (() => {
      const isFirstRequest = request.forceRefresh;
      const isBrowserReload = (performance.getEntriesByType('navigation')[0] as unknown as {type:string}).type === 'reload';

      if (isFirstRequest && !isBrowserReload) return undefined;

      const fromClaim = this.idTokenClaims()?.organizationId;
      if (fromClaim) return fromClaim;

      const fromSession = this.#config.enableSessionOnReload
        && sessionStorage.getItem(STORAGE_KEYS.toid);

      if (fromSession) {
        this.#i18nService.t$('notifications.auth.keep_old_toid_if_reload')
        .pipe(
          takeUntilDestroyed(this.#destroyRef),
          tap(message => this.#toastService.info(message)),
        ).subscribe();

        return fromSession;
      }

      return undefined;
    })();
    if (toid) request.tokenBodyParameters!.toid = toid;

    // For E2E tests to bypass recaptcha during adb2c flows
    const cbt = this.#platformConfigService.get('AUTH_CAPTCHA_BYPASS_TOKEN');
    if (cbt && cbt !== '{IAC_AADB2C_CAPTCHA_BYPASS_TOKEN}') request.tokenBodyParameters!.cbt = cbt;

    return this.#msalService!.acquireTokenSilent(request)
    .pipe(
      // store toid in session so may enforce if relaod tab (but not navigate)
      tap((_response) => {
        const { organizationId } = this.idTokenClaims() ?? {};
        if (organizationId && this.#config.enableSessionOnReload) {
          sessionStorage.setItem(STORAGE_KEYS.toid, organizationId);
        }
      }),
      // if silent token acquisition fails, fallback to interactive method
      catchError((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          return this.#msalGuardConfig!.interactionType === InteractionType.Popup
            ? this.#msalService!.acquireTokenPopup(request)
            : this.#msalService!.acquireTokenRedirect(request);
        }

        throw error;
      }),
    );
  }

  /** > ?redirect_uri to fail (public) route because need org */
  redirectToCreateOrganization(redirectUri?:string):void {
    const { organization } = this.#config.redirects;
    const { admin } = this.#platformConfigService.get('uris');
    const uri = `${organization}?${[
      `lang=${this.#language()}`,
      `redirect_uri=${redirectUri ?? admin}`,
    ].join('&')}`;

    window.location.href = uri;
  }

  /**
   * ! ---------------- FLOWS ----------------
   */

  /** On inProgress : not via redirect but std flow
   * - here we select the active account (if found)
   * - and force a refresh token if found user was already connected before
   */
  #onInProgress() {
    this.#checkAndSetConnexion();
    this.#checkAndSetActiveAccount();

    const { clientId } = this.#config;
    const accountFound = this.#accountFound();
    const accessToken = this.#accessToken();
    const forceLogin = accountFound && !accessToken;
    this.#debug('onInProgress', { accountFound, accessToken, forceLogin });

    if (!forceLogin) {
      this.#isAuthenticated.set(accountFound && !!accessToken);

      return;
    }

    /* call token with refresh */
    this.requestAccessToken([clientId], { forceRefresh: true }).subscribe();

    // /* OR call token with code challenge */
    // this.login();
  }

  /** On redirect success - here have an updated token
   * #onRedirectSuccessFromSignupSignin will match login or aquireToken - and event register will pass through here
   *
   * * if no orgId within token claim => redirect org creation
   * * for now only support SIGN_UP_SIGN_IN policy
   */
  #onRedirectSuccess(result: EventMessage) {
    const payload = result.payload as AuthenticationResult;
    const idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

    this.#idTokenClaims.set(idtoken);

    const mustCreateOrganization = !idtoken.organizationId
      && this.#config.forceOrganization;

    this.#debug('onRedirectSuccess', { result, idtoken, mustCreateOrganization });
    if (mustCreateOrganization) {
      this.#waitLang$.subscribe(() => {
        this.redirectToCreateOrganization();
      });
    } else {
      this.#isAuthenticated.set(true);
    }

    this.#msalService!.instance.setActiveAccount(payload.account);
    this.#saveAccessToken(payload.accessToken);

    if (payload.state) this.#state.set(payload.state);

    return result;
  }

  /** On login fail
   * - if silent - try loginRedirect
   * else... just logging here but flow will ends not connected
   */
  #onLoginFailure(result: EventMessage) {
    this.#debug('onLoginFailure', result);
    if (result.error) {
      const { message } = result.error;

      const knownError = Object.keys(ADB2CErrors).find((key) =>
        message.indexOf(ADB2CErrors[key as keyof typeof ADB2CErrors]) > -1,
      );

      console.warn(`Auth : ${knownError?.replace(/_/g, ' ').toLowerCase() ?? 'redirect failed'}`);

      // reloading allow to restart all flow
      // because guard stated fail but maybe we are still connected
      if (message.indexOf(ADB2CErrors.CANCELED_OPERATION) > -1) {
        window.location.reload();
      }
    }

    this.#isAuthenticated.set(false);

    return result;
  }

  /**
   * ! ---------------- PRIVATE ----------------
   */

  /** subtility : isConnected does not mean have access (expired token) / if not, cancel flow here */
  #checkAndSetConnexion(): void {
    const accounts = this.#msalService!.instance.getAllAccounts();
    const isConnected = accounts.length > 0;
    this.#accountFound.set(isConnected);
  }

  #checkAndSetActiveAccount():Maybe<AccountInfo> {
    const { clientId } = this.#config;
    const accounts = this.#msalService!.instance.getAllAccounts();
    let activeAccount = this.#msalService!.instance.getActiveAccount() ?? undefined;

    if (!activeAccount && accounts.length > 0) {
      const matchAccount = accounts.find((account) => account.idTokenClaims?.aud === clientId) ?? accounts[0];
      activeAccount = matchAccount;
      this.#msalService!.instance.setActiveAccount(activeAccount);
    }

    return activeAccount;
  }

  #saveAccessToken(accessToken?:string):void {
    if (accessToken === this.#accessToken()
      || accessToken === ''
    ) return;
    this.#accessToken.set(accessToken);
  }

  /**
   * ! ---------------- OBSERVABLES ----------------
   */

  #inProgress$(): Observable<InteractionStatus> {
    return this.#msalBroadcastService!.inProgress$
      .pipe(filter((status: InteractionStatus) =>
        status === InteractionStatus.None,
      ));
  }

  #redirectSuccess$(): Observable<EventMessage> {
    return this.#msalBroadcastService!.msalSubject$
      .pipe(filter(({ eventType }) => ([
          EventType.LOGIN_SUCCESS,
          EventType.ACQUIRE_TOKEN_SUCCESS,
          EventType.SSO_SILENT_SUCCESS,
        ] as EventType[]).includes(eventType)),
      );
  }

  #aquireTokenFailure$(): Observable<EventMessage> {
    return this.#msalBroadcastService!.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
    );
  }

  #loginFailure$(): Observable<EventMessage> {
    return this.#msalBroadcastService!.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE),
    );
  }

  #redirectFailure$(): Observable<EventMessage> {
    return merge(
      this.#aquireTokenFailure$(),
      this.#loginFailure$(),
    );
  }

  #checkConnection$():Observable<boolean> {
    return this.accountFound()
    ? of(true)
    : throwError(() => new Error('auth: not connected'));
  }

  #checkValidToken$():Observable<Maybe<string>> {
    return merge(
      this.accessToken$,
      // Fail if not silent redirect - perfect since all silent fallback to not silent login
      this.#redirectFailure$()
      .pipe(
        filter(({ interactionType }) => interactionType !== InteractionType.Silent),
        switchMap(() => throwError(() => new Error('auth: login failed'))),
      ),
    );
  }

  #debug(msg:string, ...args:unknown[]):void {
    if (!this.#platformConfigService.get('DEBUG_AUTH_FLOW')) return;

    // eslint-disable-next-line no-console
    console.debug(`[auth] : ${msg}`, ...args);
  }
}
