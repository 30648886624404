<div class="svg-icon svg-icon--{{ size }}">
  @if(!imgUrl) {
    <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    role="img"
    focusable="false"
    class="svg-icon__symbol"
  >
    @if(alt) {
      <title>{{ alt }}</title>
    }
    @if(icon){
      <use attr.xlink:href="{{ basePath() }}icons/sprite/sprite.svg#{{ icon }}"></use>
    }
    @if(svgPath){
      <use [attr.xlink:href]="svgPath"></use>
    }
  </svg>
  }
  @else {
    <img [src]="imgUrl" [attr.alt]="alt" class="svg-icon__img"/>
  }
</div>
